<template>
  <div>
    <div
      v-for="(startseiteComponent, index) in startseiteComponents"
      :key="index"
    >
      <div class="row">
        <div
          v-for="(component, index) in startseiteComponent.row"
          :key="index"
          :class="component.col"
        >
          <component
            :is="component.name"
            v-if="component.active == true"
            :angemeldeterMitarbeiter="angemeldeterMitarbeiter"
            :kachelStyle="component.kachelStyle"
            :kachelCol="component.kachelCol"
            :kachelKleinStyle="component.kachelKleinStyle"
            :kachelKleinCol="component.kachelKleinCol"
            @loading="loading = true"
            @stopLoading="loading = false"
          />
        </div>
      </div>
    </div>
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>
  
  <script>
import Api from "@/Api";
import store from "@/store";

import LoadingOverlay from "@/components/global/LoadingOverlay";

import StartseitePendenzen from "@/components/Startseite/StartseitePendenzen";
import StartseiteLeads from "@/components/Startseite/StartseiteLeads";
import StartseiteAnmeldungenStatistik from "@/components/Startseite/StartseiteAnmeldungenStatistik";
import StartseiteCRM from "@/components/Startseite/StartseiteCRM";
import StartseiteDozierende from "@/components/Startseite/StartseiteDozierende";
import StartseiteBildung from "@/components/Startseite/StartseiteBildung";
import StartseiteAnmeldungen from "@/components/Startseite/StartseiteAnmeldungen";
import StartseiteFinanzen from "@/components/Startseite/StartseiteFinanzen";
import StartseiteRaeumeTermine from "@/components/Startseite/StartseiteRaeumeTermine";
import StartseiteKommunikation from "@/components/Startseite/StartseiteKommunikation";
import StartseiteSubventionen from "@/components/Startseite/StartseiteSubventionen";
import StartseiteLeadManagement from "@/components/Startseite/StartseiteLeadManagement";
import StartseiteTor from "@/components/Startseite/StartseiteTor";

export default {
  name: "StartseiteEducation",
  components: {
    LoadingOverlay,
    StartseitePendenzen,
    StartseiteLeads,
    StartseiteAnmeldungenStatistik,
    StartseiteCRM,
    StartseiteDozierende,
    StartseiteBildung,
    StartseiteAnmeldungen,
    StartseiteFinanzen,
    StartseiteRaeumeTermine,
    StartseiteKommunikation,
    StartseiteSubventionen,
    StartseiteLeadManagement,
    StartseiteTor,
  },
  store,
  mixins: [],
  data() {
    return {
      angemeldeterMitarbeiter: null,
      startseiteComponents: [],
      loading: false,
    };
  },
  computed: {},
  watch: {
    angemeldeterMitarbeiter: {
      deep: true,
      handler: function () {
        if (this.startseiteComponents.length == 0) {
          this.initializeDashboard();
        }
      },
    },
  },
  created() {
    if (!this.angemeldeterMitarbeiter) {
      Api.get("aktueller_mitarbeiter/").then((response) => {
        this.angemeldeterMitarbeiter = response.data.mitarbeiter;
        this.angemeldeterMitarbeiter.dashboard = response.data.dashboard;

        this.angemeldeterMitarbeiter.dashboard.json = JSON.parse(
          response.data.dashboard.json
        );
      });
    }
  },
  mounted() {},
  methods: {
    initializeDashboard() {
      var maxRow = 5;

      //Components Array den entsprechenden Rows zuweisen:
      for (let i = 0; i < maxRow; i++) {
        this.angemeldeterMitarbeiter.dashboard.json.forEach((el) => {
          if (el.row == i) {
            if (this.startseiteComponents[i]) {
              this.startseiteComponents[i].row.push(el);
            } else {
              this.startseiteComponents.push({ row: [] });
              this.startseiteComponents[i].row.push(el);
            }
          }
        });
      }

      //einzelne Rows sortieren anhand Sortierungs-Wert aus JSON:
      this.startseiteComponents.forEach((el) => {
        el.row.sort(function (a, b) {
          return a.sortierung - b.sortierung;
        });
      });
    },
  },
};
</script>
  
  <style lang="scss">
</style>
  